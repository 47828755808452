// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import { getVideoProviderListFailed, getVideoProviderListRequested, getVideoProviderListSucceeded } from './select-video-provider.actions';
import { VideoProviderListResponse } from '@par/app/core/model/dictionaries.response';

export const STATE_NAME_SELECT_VIDEO_PROVIDER = 'select-video-provider';

export interface State {
  response?: VideoProviderListResponse;
  responseError: boolean;
  responseLoading: boolean;
}

const initialState: State = {
  response: undefined,
  responseError: false,
  responseLoading: false,
};

const reducer = createReducer(
  initialState,
  on(getVideoProviderListRequested, (state) => ({...state, response: undefined, responseLoading: true, responseError: false})),
  on(getVideoProviderListSucceeded, (state, {response}) => ({...state, responseLoading: false, response, responseError: false})),
  on(getVideoProviderListFailed, (state) => ({...state, responseLoading: false, responseError: true})),
);

export function selectVideoProviderReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

